































































































































import PasswordProfile from "@/views/pages/Profile/Password.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import DatetimePicker from "vue-persian-datetime-picker";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import VueCropper from "vue-cropperjs";
import moment from "moment-jalaali";
import "cropperjs/dist/cropper.css";
import Office from "@/views/components/inputs/office.vue";
import Enum from "@/config/enum";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

const persianAlpha = (value = "") => !!value.match(/^[\u0600-\u06FF\s]+$/);

@Component({
  components: {
    DatetimePicker,
    PasswordProfile,
    VueCropper,
    office: Office
  }
})
export default class MainProfile extends Vue {
  private form: Record<string, unknown> = this.$store.state.user;
  private errors: Record<string, Array<string>> = {};
  private datePopup = false;
  showDialog = false;
  private today = moment().format("jYYYY-jMM-jDD");
  private baseUrl = process.env.VUE_APP_URL || "https://api.bms-go2tr.com";
  private roles = localStorage.getItem("roles") || [];
  private types = Enum.military_status;

  validations(): Record<string, any> {
    return {
      form: {
        fname: {
          required,
          persianAlpha
        },
        lname: {
          required,
          persianAlpha
        },
        national_code: {
          maxLength: maxLength(10),
          minLength: minLength(10)
        }
      }
    };
  }

  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new ProfileApi().editProfile(form);

      if (res.data.data) {
        localStorage.setItem("user", JSON.stringify(res.data.data));
      }

      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  @Watch("$store.state.user")
  public onDataChanged() {
    this.form = this.$store.state.user;
  }
}
